<template>
  <survey-form />
</template>

<script>
import SurveyForm from "@/views/components/forms/surveys/SurveysForm.vue";

export default {
  components: {
    SurveyForm
  }
};
</script>